<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        sm="12"
        md="7"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-form-group
              :label="$t('apps.masterDataProductCategory.singular.code')"
              label-for="code"
            >
              <b-form-input
                id="category-code"
                v-model="form.code"
                :placeholder="$t('apps.masterDataProductCategory.placeholder.code')"
                :disabled="actions.isPreview"
              />
            </b-form-group>

            <b-form-group
              :label="$t('apps.masterDataGroupElement.moduleName')"
              label-for="main-activity">
              <validation-provider
                #default="{ errors }"
                name="main-activity"
                rules="required"
              >
                <v-select
                  id="main-activity"
                  v-model="form.groupElementId"
                  :options="LOV.groupActivities"
                  :reduce="field => field.id"
                  label="name"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('apps.masterDataGroupElement.moduleName')  : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('apps.masterDataProductCategory.singular.name')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="category-name"
                  v-model="form.name"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="!actions.isPreview ? $t('apps.masterDataProductCategory.placeholder.name') : null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('apps.masterDataProductCategory.singular.description')"
              label-for="category-description"
            >
              <b-form-textarea
                id="category-description"
                v-model="form.description"
                :placeholder="!actions.isPreview ? $t('apps.masterDataProductCategory.placeholder.description') : null"
                :disabled="actions.isPreview"
              />
            </b-form-group>

            <b-form-group
              :label="$t('globalSingular.account')"
              label-for="account-id">
              <validation-provider
                #default="{ errors }"
                name="account-id"
                rules="required"
              >
                <v-select
                  id="account-id"
                  v-model="form.accountId"
                  :options="LOV.accounts"
                  :reduce="field => field.id"
                  label="label"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('globalSingular.account')  : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-element-activity-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    BFormTextarea,
    BButton,
    // BFormText,
    // BFormDatalist,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/element', localeContextPath: 'apps.masterDataElement.moduleName', redirectPathName: 'apps-master-data-element-activity' })

    const LOV = ref({
      groupActivities: [],
      accounts: []
    })

    const form = ref({
      code: '',
      name: '',
      groupElementId: '',
      accountId: '',
      description: ''
    })

    const getGroupActivities = async () => {
      LOV.value.groupActivities = await get({ url: 'master/element-group' })
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts'})
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data[0].code
      form.value.name = data[0].name
      form.value.description = data[0].description
      form.value.groupElementId = data[0].group_element_id
      form.value.accountId = data[0].account_id
    }

    onMounted(() => {
      getAccounts()
      getGroupActivities()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
    })

    return {
      LOV,
      required,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
